var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"image-boxes-wrap"},[_c('div',{staticClass:"image-boxes mt3",style:(("max-width: " + (_vm.componentMaxWidth + 10) + "px;"))},_vm._l((_vm.uploads),function(image,index){return _c('img',{key:image._id,ref:'img' + index,refInFor:true,staticClass:"box",attrs:{"id":_vm.getId(),"src":image.image_url},on:{"click":function($event){return _vm.imgClicked(index, image)}}})}),0)]),_c('loading',{attrs:{"active":_vm.$wait.is('loadingImages') ||
        _vm.$wait.is('uploadingImage') ||
        _vm.$wait.is('deletingImage'),"is-full-page":false}}),_c('uploader',{attrs:{"styles":_vm.uploaderStyles,"textColor":"#fff","showDescription":false,"supportedFileTypes":_vm.supportedFileTypes},on:{"upload":function (file) {
        this$1.$_dashboardMixin_uploadImageToS3(file, _vm.supportedFileTypes).then(
          function () {
            this$1.getAllUploads();
          }
        );
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="image-boxes-wrap">
      <div
        class="image-boxes mt3"
        :style="`max-width: ${componentMaxWidth + 10}px;`"
      >
        <img
          class="box"
          @click="imgClicked(index, image)"
          v-for="(image, index) in uploads"
          :ref="'img' + index"
          :id="getId()"
          :src="image.image_url"
          :key="image._id"
        />
      </div>
    </div>

    <loading
      :active="
        $wait.is('loadingImages') ||
          $wait.is('uploadingImage') ||
          $wait.is('deletingImage')
      "
      :is-full-page="false"
    ></loading>

    <uploader
      :styles="uploaderStyles"
      textColor="#fff"
      :showDescription="false"
      @upload="
        (file) => {
          this.$_dashboardMixin_uploadImageToS3(file, supportedFileTypes).then(
            () => {
              this.getAllUploads();
            }
          );
        }
      "
      :supportedFileTypes="supportedFileTypes"
    ></uploader>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { generateID } from "@/utils";
import { GRAPHICS_MIXIN } from "./graphics.mixins";
import { DASHBOARD_MIXIN } from "@/components/common/mixins/dashboard.mixins";
import uploader from "@/components/common/uploader/uploader.component";

import { loadAWSsdk } from "@/libs/aws-manager";

export default {
  data: function() {
    return {
      uploads: [],
      supportedFileTypes: ["png", "jpg", "jpeg", "gif", "bmp", "svg"],
    };
  },
  mixins: [GRAPHICS_MIXIN, DASHBOARD_MIXIN],
  components: {
    uploader,
  },
  props: {
    addImageToCanvasOnClick: {
      type: Boolean,
      default: false,
    },
    componentMaxWidth: {
      type: Number,
      default: 330,
    },
    uploaderStyles: {
      default: function() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters("brandModule", ["userUploadedImages"]),
    ...mapActions("brandModule", ["getUserUploadedImages"]),
  },
  watch: {
    userUploadedImages: function(newProps) {
      this.uploads = newProps;
    },
  },
  mounted: function() {
    loadAWSsdk();

    if (this.uploads.length === 0) {
      this.getAllUploads();
    }
  },
  methods: {
    getId: function() {
      return generateID();
    },
    imgClicked: function(index, image) {
      if (this.addImageToCanvasOnClick) {
        this.$_graphicsMixin_addImgToCanvas(index);
      }
      this.$emit("imgClicked", image);
    },

    getAllUploads: async function() {
      this.$wait.start("loadingImages");

      this.$store
        .dispatch("brandModule/getUserUploadedImages")
        .then((response) => {
          this.$wait.end("loadingImages");

          this.uploads = response;
        })
        .catch((e) => {
          this.$wait.end("loadingImages");

          console.error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-boxes-wrap {
  min-height: calc(100vh - 297px);
}
</style>

<template>
  <div
    class="el-upload-dragger"
    @dragover="onDragOver"
    @dragenter="onDragOver"
    @drop="onDrop"
    @click="selectFile"
    :style="styles"
  >
    <i class="el-icon-upload"></i>
    <div class="el-upload__text" :style="{ color: textColor }">
      <h3>Drop {{ uploaderType }} here or <em>click to upload</em></h3>
      <p v-if="showDescription">
        You can upload {{ uploaderType }} in
        {{ supportedFileTypes.join(", ") }}. Maximum file size 10MB. <br />
        <span v-if="showNotice" class="light" :style="{ color: textColor }">
          By uploading the {{ uploaderType }}(s), you confirm that you have the
          right to use those {{ uploaderType }}(s) for your intended purpose.
        </span>
      </p>
    </div>

    <input
      @change="
        (e) => {
          const file = e.target.files[0];
          this.$emit('upload', file);
        }
      "
      type="file"
      style="display:none;"
      ref="attach"
      name="attach"
      :accept="`${uploaderType}/*, .${supportedFileTypes.join(',.')}`"
    />
  </div>
</template>

<script>
export default {
  name: "uploader",
  props: {
    uploaderType: {
      type: String,
      default: "image",
    },
    supportedFileTypes: {
      type: Array,
      default: function() {
        return ["png", "jpg", "jpeg", "gif", "bmp", "svg"];
      },
    },
    styles: {
      type: Object,
      default: function() {
        return {};
      },
    },
    textColor: {
      type: String,
      default: "#333",
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    showNotice: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onDragOver(evt) {
      evt.preventDefault();
    },
    onDrop(e) {
      e.preventDefault();
      const file = event.dataTransfer.files[0];
      this.$emit("upload", file);
    },
    selectFile() {
      const elInput = this.$refs.attach;
      elInput.click();
      elInput.value = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div
    class="image-boxes mt3"
    :style="`max-width: ${componentMaxWidth + 10}px;`"
  >
    <el-input
      @change="changeKeyword"
      placeholder="Search"
      class="mb3"
      :style="`max-width: ${componentMaxWidth}px;`"
      v-model="search"
    ></el-input>
    <img
      class="box"
      @click="imgClicked(index, image)"
      v-for="(image, index) in images"
      :ref="'img' + index"
      :id="getId()"
      :src="image.urls.regular"
      :key="image.urls.regular"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { generateID } from "@/utils";
import { GRAPHICS_MIXIN } from "./graphics.mixins";

export default {
  data: function() {
    return {
      search: "",
      pageSize: 30,
      pageNumber: 1,
    };
  },
  mixins: [GRAPHICS_MIXIN],
  props: {
    addImageToCanvasOnClick: {
      type: Boolean,
      default: false,
    },
    componentMaxWidth: {
      type: Number,
      default: 330,
    },
  },
  computed: {
    ...mapGetters("elementsModule", ["stockImages", "stockImagesPage"]),
    ...mapActions("elementsModule", ["getStockImages"]),
    images: function() {
      return this.stockImages.map((s) => {
        return {
          urls: s.urls,
        };
      });
    },
  },
  mounted: function() {
    if (this.stockImages.length === 0) {
      this.getImages();
    }
  },
  methods: {
    getId: function() {
      return generateID();
    },
    imgClicked: function(index, image) {
      if (this.addImageToCanvasOnClick) {
        this.$_graphicsMixin_addImgToCanvas(index);
      }
      this.$emit("imgClicked", image);
    },
    changeKeyword: function(value) {
      this.getImages();
    },
    getImages: async function() {
      await this.$store.dispatch("elementsModule/getStockImages", {
        keyword: this.search,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
  },
};
</script>

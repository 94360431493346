<template>
  <div id="graphics-icons">
    <el-collapse>
      <el-collapse-item title="Ecommerce" name="ecommerce">
        <ecommerce></ecommerce>
      </el-collapse-item>
      <el-collapse-item title="Marketing" name="marketing">
        <marketing></marketing>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { GRAPHICS_MIXIN } from "./graphics.mixins";

export default {
  mixins: [GRAPHICS_MIXIN],

  components: {
    ecommerce: () => import("./icons-categories/ecommerce.component.vue"),
    marketing: () => import("./icons-categories/marketing.component.vue"),
  },
  data: function() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "index.component.scss";
</style>

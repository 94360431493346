<template>
  <div id="graphics">
    <div class="sidebar-header" v-if="clickedBoxIndex !== null">
      <div class="header">
        <div class="close" @click="closeBox">
          <el-button icon="el-icon-back" type="text"></el-button>
        </div>
        <div class="title">{{ boxes[clickedBoxIndex].name }}</div>
      </div>
    </div>
    <div class="sidebar-boxes" v-if="clickedBoxIndex === null">
      <div
        v-for="(box, index) in boxes"
        :key="index"
        @click="boxClicked(index)"
        class="box"
      >
        <div class="img">
          <img
            :style="box.style"
            :src="`${publicPath}${iconPath}/${box.img}`"
            alt=""
          />
        </div>
        <h4>{{ box.name }}</h4>
      </div>
    </div>
    <my-uploads
      v-if="clickedBoxIndex === 0"
      :addImageToCanvasOnClick="true"
      :uploaderStyles="{
        width: '328px',
        height: '165px',
        left: '8px',
        'background-color': 'transparent',
        'border-color': '1px dashed #999',
      }"
    ></my-uploads>
    <stock-images
      v-if="clickedBoxIndex === 1"
      :addImageToCanvasOnClick="true"
    ></stock-images>
    <shapes v-if="clickedBoxIndex === 2" />
    <icons v-if="clickedBoxIndex === 3" />
  </div>
</template>

<script>
import icons from "./icons.component";
import stockImages from "./stock-images.component";
import myUploads from "./my-uploads.component";
import shapes from "./shapes.component";

export default {
  data: function() {
    return {
      iconPath: "assets/sidebar-graphic-icons",
      clickedBoxIndex: null,
      boxes: [
        {
          name: "MY UPLOADS",
          img: "upload.svg",
        },
        {
          name: "STOCK PHOTOS",
          img: "camera.svg",
        },
        {
          name: "SHAPES",
          img: "shapes.svg",
        },
        // {
        //   name: "ILLUSTRATIONS",
        //   img: "illustrations.svg",
        // },
        {
          name: "ICONS",
          img: "icons.svg",
          style: `transform: scale(1.15)`,
        },
      ],
    };
  },
  components: {
    icons,
    "stock-images": stockImages,
    "my-uploads": myUploads,
    shapes,
  },
  methods: {
    boxClicked(index) {
      this.clickedBoxIndex = index;
    },
    closeBox() {
      this.clickedBoxIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-boxes {
  .img {
    img {
      max-width: 50px;
    }
  }
}

.sidebar-header {
  .header {
    text-align: center;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-weight: 700;
    .close {
      position: absolute;
      color: #fff;
      left: 20px;
      button {
        color: inherit;
        font-size: 24px;
        width: 35px;
        padding: 4px;
        height: 35px;

        &:hover {
          background: $color-dark;
        }
      }
    }
  }
}
</style>

<template>
  <div id="graphics-shapes">
    <div
      style="margin-left:15px;"
      class="shapes graphics-wrap color-overlay-icons"
    >
      <div
        class="item"
        @click="addELToCanvas(shape)"
        v-for="(shape, i) in shapes"
        :key="i"
      >
        <div :style="shape.style" :class="'shape ' + shape.name"></div>
      </div>
    </div>
    <el-collapse>
      <el-collapse-item title="Arrows" name="arrows">
        <arrows></arrows>
      </el-collapse-item>
      <el-collapse-item title="Blobs" name="blobs">
        <blobs></blobs>
      </el-collapse-item>
      <el-collapse-item title="Geometric Shapes" name="geometric">
        <geometric></geometric>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { GRAPHICS_MIXIN } from "./graphics.mixins";

export default {
  mixins: [GRAPHICS_MIXIN],

  components: {
    geometric: () =>
      import("./shapes-categories/geometric-shapes.component.vue"),
    blobs: () => import("./shapes-categories/blobs.component.vue"),
    arrows: () => import("./shapes-categories/arrows.component.vue"),
  },
  data: function() {
    return {
      shapes: [
        {
          name: "rectangle",
          style: `height: 40px; width: 40px; `,
          borderColor: `rgb(51, 51, 51)`,
          borderWidth: `2px`,
          borderStyle: `solid`,
          borderRadius: `0`,
          elH: `100px`,
          elW: `100px`,
        },

        {
          name: "rounded-rectangle",
          style: `border-radius: 10px; height: 40px; width: 40px; `,
          borderColor: `rgb(51, 51, 51)`,
          borderWidth: `2px`,
          borderStyle: `solid`,
          borderRadius: `10px`,
          elH: `100px`,
          elW: `100px`,
        },

        {
          name: "circle",
          style: `border-radius: 50px; height: 40px; width: 40px; `,
          borderColor: `rgb(51, 51, 51)`,
          borderWidth: `2px`,
          borderStyle: `solid`,
          borderRadius: `100px`,
          elH: `100px`,
          elW: `100px`,
          keepRatio: true,
        },

        {
          name: "line",
          style: ` height: 0px; width: 40px; `,
          borderColor: "none",
          backgroundColor: `rgb(51, 51, 51)`,
          borderWidth: `0px`,
          borderStyle: `none`,
          borderRadius: 0,
          elH: `2px`,
          elW: `100px`,
        },
      ],
    };
  },
  methods: {
    addELToCanvas: function(shape) {
      this.$_graphicsMixin_addHTMLToCanvas(shape);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "index.component.scss";

.shapes {
  margin: 20px 0;

  .item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    justify-content: center;

    .shape {
      border: 2px solid #d9dadd;
    }

    &:hover {
      background: darken($color-blue-grey-dark, 1%);
    }
  }
}
</style>

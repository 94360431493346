import { s3upload } from "@/libs/aws-manager";
import { mapActions, mapGetters } from "vuex";
import config from "@/config";

export const DASHBOARD_MIXIN = {
  name: "dashboardMixin",
  computed: {
    ...mapGetters("authModule", ["userInfo"]),
    ...mapGetters("projectsModule", ["projects", "checkedProjects"]),
    ...mapActions("brandModule", ["uploadImage"]),
    ...mapActions("projectsModule", ["getUserProjects"]),
  },
  methods: {
    $_dashboardMixin_getProjects(queryObj) {
      return new Promise((resolve, reject) => {
        this.$wait.start("isLoadingProjects");

        this.$store
          .dispatch("projectsModule/getUserProjects", queryObj)
          .then((response) => {
            this.$wait.end("isLoadingProjects");
            resolve(response);
          })
          .catch((e) => {
            this.$wait.end("isLoadingProjects");

            console.error(e);
            reject(e);
          });
      });
    },
    $_dashboardMixin_removeProjects(projectIds) {
      this.$wait.start("isLoadingProjects");
      this.$store
        .dispatch("projectsModule/deleteProjects", projectIds)
        .then((responses) => {
          this.$wait.end("isLoadingProjects");
        })
        .catch((e) => {
          this.$wait.end("isLoadingProjects");

          console.error(e);
        });
    },
    $_dashboardMixin_moveProjectsToFolder(projectIds, folderId) {
      return new Promise((resolve, reject) => {
        this.$wait.start("isLoadingProjects");
        const payloads = projectIds.map((p) => {
          return {
            id: p,
            body: {
              folder_id: folderId,
            },
          };
        });
        this.$store
          .dispatch("projectsModule/moveProjectsToFolder", payloads)
          .then((responses) => {
            this.$wait.end("isLoadingProjects");
            resolve(responses);
          })
          .catch((e) => {
            this.$wait.end("isLoadingProjects");
            console.error(e);
            reject(e);
          });
      });
    },
    $_dashboardMixin_renameProject(id, name) {
      return new Promise((resolve, reject) => {
        this.$wait.start("savingProject");
        this.$store
          .dispatch("projectsModule/updateProject", {
            id: id,
            body: {
              file_name: name,
            },
          })
          .then((response) => {
            this.$wait.end("savingProject");
            resolve(response);
          })
          .catch((e) => {
            this.$wait.end("savingProject");
            reject(e);
            console.error(e);
          });
      });
    },
    $_dashboardMixin_uploadToS3(
      file,
      supportedFileTypes,
      userInfo,
      folder,
      changeFileName
    ) {
      return new Promise((resolve, reject) => {
        let fileobj = null;

        const ext = file.name.substr(file.name.lastIndexOf(".") + 1);

        if (supportedFileTypes.indexOf(ext) === -1) {
          reject("Unsupported file type.");
        }

        if (file.size < 10 * 1024 * 1024) {
          fileobj = new File([file], file.name, {
            type: file.type,
          });
        } else {
          reject(
            "Maximum supported file size is 10 MB. Please choose a smaller file"
          );
        }

        if (fileobj && userInfo) {
          s3upload(fileobj, userInfo._id, folder, changeFileName)
            .then((uploaded) => {
              resolve({
                inputFile: file,
                uploaded,
              });
            })
            .catch((e) => {
              reject('There was an error uploading your file"');
            });
        }
      });
    },
    async $_dashboardMixin_uploadImageToS3(file, supportedFileTypes) {
      if (!window.AWS) return;

      this.$wait.start("uploadingImage");
      try {
        const s3File = await this.$_dashboardMixin_uploadToS3(
          file,
          supportedFileTypes,
          this.userInfo,
          config.S3_IMAGES_FOLDER,
          true
        );

        const payload = {
          key: s3File.uploaded.Key,
          image_url: s3File.uploaded.Location,
          file_size: s3File.inputFile.size,
          storage_location: "internal",
        };
        await this.$store.dispatch("brandModule/uploadImage", payload);
        this.$wait.end("uploadingImage");
      } catch (e) {
        this.$message({
          type: "error",
          message: e,
        });
        this.$wait.end("uploadingImage");
      }
    },
    async $_dashboardMixin_uploadFontToS3(file, supportedFileTypes) {
      if (!window.AWS) return;

      try {
        this.$wait.start("uploadingFont");

        const s3File = await this.$_dashboardMixin_uploadToS3(
          file,
          supportedFileTypes,
          this.userInfo,
          config.S3_FONTS_FOLDER,
          false
        );

        const payload = {
          key: s3File.uploaded.Key,
          font_url: s3File.uploaded.Location,
          font_name: s3File.inputFile.name.substr(
            0,
            s3File.inputFile.name.lastIndexOf(".")
          ),
          file_size: s3File.inputFile.size,
        };
        await this.$store.dispatch("brandModule/uploadFont", payload);
        this.$wait.end("uploadingFont");
      } catch (e) {
        this.$message({
          type: "error",
          message: e,
        });
        this.$wait.end("uploadingFont");
      }
    },
  },
};
